import { Button, DataSource, HorizontalSpacer, Label, Layout, Map, Panel, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutTrackWithoutLogin extends Layout {
    buttonSearch: Button;
    horizontalspacer2: HorizontalSpacer;
    labelNoOrdersFound: Label;
    labelOrder: Label;
    label_: Label;
    mapStops: Map;
    panelDetails: Panel;
    panelTrackAndTrace: Panel;
    sourceCustomerOrdersUnauthenticated: DataSource;
    tabMap: Tab;
    tabStops: Tab;
    tableStops: Table;
    tabsetDetails: Tabset;
    textboxConsigneeCityName: Textbox;
    textboxConsigneeRefno: Textbox;
    textboxConsigneeState: Textbox;
    textboxOrdersBlnum: Textbox;
    textboxSearchType: Textbox;
    textboxShipperCityName: Textbox;
    textboxShipperSchedArriveEarly: Textbox;
    textboxShipperState: Textbox;
    textboxStatus: Textbox;
    textboxTrackingNumber: Textbox;
}
