import { Button, ClickEvent, Component, DataDisplayEvent, Label, Panel, TableRow, TableRowDisplayEvent, TableRowExpansionEvent } from "@mcleod/components";
import { GeneralSettings, ModelRow, StringUtil } from "@mcleod/core";
import { FactoringOrdersDetails } from "./FactoringOrdersDetails";
import { AutogenLayoutTablePaymentsDue } from "./autogen/AutogenLayoutTablePaymentsDue";

export class TablePaymentsDue extends AutogenLayoutTablePaymentsDue {

    factoringOrdersDetailsLayout: FactoringOrdersDetails;
    tabSelected: string;

    override onLoad() {
        this.mainDataSource.search();
    }

    /** This is an event handler for the onRowDisplay event of tablePaymentsDue.  */
    tablePaymentsDueOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        tableRow.forEveryChildComponent((component: Component) => this.setNotAvailable(component, tableRow));

        const statusLabel = tableRow?.findComponentById("labelStatus") as Label;
        const orderIdLabel = tableRow?.findComponentById("labelOrderId") as Label;
        const manifestIdLabel = tableRow?.findComponentById("labelManifestId") as Label;
        tableRow.data?.get("status") == "Pending" ? statusLabel.imageColor = "warning" : statusLabel.imageColor = "error";
        statusLabel.imageProps = { ...statusLabel.imageProps, paddingLeft: 0, marginLeft: 0 };
        if (tableRow.data?.get("manifest_id")) {
            manifestIdLabel.visible = true;
            orderIdLabel.visible = false;
        }
    }

    setNotAvailable(component: Component, tableRow: TableRow) {
        if (component instanceof Label && component.field != null) {
            if (StringUtil.isEmptyString(tableRow.data?.get(component.field)))
                component.caption = "--";
        }
    }

    table1OnRowExpand(event: TableRowExpansionEvent) {
        const orderModelRow = event.getTableRow()?.data as ModelRow;
        const id = orderModelRow?.get("id");
        const movementId = orderModelRow?.get("movement_id");
        const orderId = orderModelRow?.get("order_id");
        const settleId = orderModelRow?.get("settle_id");
        const expandPanel = event.expandComponentParent as Panel;

        const addImagesToolPanel = expandPanel.findComponentById("panelImageTools") as Panel;
        const addImagesButton = addImagesToolPanel.findComponentById("buttonAddImages") as Button;
        this.factoringOrdersDetailsLayout = expandPanel.findComponentById("layoutFactOrderDetails") as FactoringOrdersDetails;
        const labelCommentStatus = event.getTableRow().cells[8].components[1] as Label;
        const labelImageStatus = event.getTableRow().cells[8].components[2] as Label;

        const imagePermission = GeneralSettings.get().permissions?.denied?.InternetModule?.["InternetModuleDocumentRetrieval"] == null
        const commentPermission = true;

        this.factoringOrdersDetailsLayout.addLayoutLoadListener(event => {

            this.factoringOrdersDetailsLayout.setSelectedTabset(this.tabSelected);
            if (commentPermission) {
                this.factoringOrdersDetailsLayout.tabComments.dataSource.search({ order_id: orderId, id: settleId });
            }
            else {
                this.factoringOrdersDetailsLayout.removeFactoringComments();
            }

            if (imagePermission) {
                this.factoringOrdersDetailsLayout.tabImages.dataSource.search({ movement_id: movementId, order_id: orderId });
                this.factoringOrdersDetailsLayout.setupFactoringImagesLayout(movementId, orderId, addImagesButton, labelImageStatus);
            }
            else {
                expandPanel.remove(addImagesToolPanel);
                this.factoringOrdersDetailsLayout.removeFactoringImages();
            }

        });
    }

    labelCommentStatusOnClick(event: ClickEvent) {
        this.tabSelected = "Comments";
        this.setTabset(event);
    }

    labelImageStatusOnClick(event: ClickEvent) {
        this.tabSelected = "Images";
        this.setTabset(event);
    }

    setTabset(event: ClickEvent) {
        const tableRow = event.target["row"] as TableRow
        if (tableRow.expanded === true) {
            this.factoringOrdersDetailsLayout.setSelectedTabset(this.tabSelected);
        }
        else {
            tableRow.setExpanded(true);
        }
    }

    label_mailOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label
        const email = event.rowData.get("contact_email");
        const invoice = event.rowData.get("invoice_num");
        if (email == null) return;

        label.link = "mailto:" + email + "?subject=Question about "
            + event.rowData.get("carrier_name") + " Order: " + event.rowData.get("order_id");
        if (invoice != null)
            label.link = label.link + " Invoice: " + invoice;
    }

    labelCommentStatusOnDataDisplay(event: DataDisplayEvent) {
        const count = event.rowData.get("comments_count");
        if (count > 0) {
            (event.target as Label).imageColor = "primary";
        }
        else {
            (event.target as Label).imageColor = "default.lightest";
        }
    }

    labelImageStatusOnDataDisplay(event: DataDisplayEvent) {
        const count = event.rowData.get("images_count");
        if (count > 0) {
            (event.target as Label).imageColor = "primary";
        }
        else {
            (event.target as Label).imageColor = "default.lightest";
        }
    }
}
