import { ClickEvent, Dialog, ImageViewer, Label, Layout, Snackbar, Tab, Table, TableRow } from "@mcleod/components";
import { FileUtil, Model, ModelRow, StringUtil, getLogger } from "@mcleod/core";
import { UploadImages } from "./UploadImages";
import { AutogenLayoutImageTable } from "./autogen/AutogenLayoutImageTable";
import { CommonDialogs } from "../CommonDialogs";

const log = getLogger("lme.general.ImageTable");

export class ImageTable extends AutogenLayoutImageTable {
    private rowType: string;
    private rowId: string;
    private imageDialog: Dialog;

    private _getImagesTab(): Tab {
        let imagesTab = this["tabImages"] as Tab;
        if (imagesTab == null && this.parent instanceof Tab) {
           imagesTab = this.parent;
        }
        return imagesTab;
    }

    private _getImagesTable(): Table {
        return this["tableImages"] as Table;
    }

    public populateForRow(rowType: string, rowId: string) {
        this.rowType = rowType;
        this.rowId = rowId;
        this.mainDataSource.search({ row_type: rowType, row_id: rowId })
            .then(r => this._updateImageTableHeader())
            .catch(error => this._updateImageTableHeader());
    }

    private _updateImageTableHeader() {
        const imagesTab = this._getImagesTab();
        if (imagesTab == null)
            return;
        if (imagesTab.headingLabel != null)
            imagesTab.headingLabel.fillRow = false;
        if (imagesTab.heading != null) {
            if (imagesTab["countLabel"] == null) {
                imagesTab["countLabel"] = new Label({
                    fontBold: true, caption: "0", fillRow: true,
                    color: "primary", rowBreak: false, marginLeft: 5
                });
                imagesTab.heading.insert(imagesTab["countLabel"], 1);
            }
            imagesTab["countLabel"].caption = this._getImagesTable().rowCount;
        }
    }

    labelDescrOnClick(event: ClickEvent) {
        const descrLabel = event.target as Label;
        descrLabel.busy = true;
        const tableRow = TableRow.getContainingTableRow(descrLabel);
        const id = (tableRow.data as ModelRow).get("id");
        Model.searchSingleRecord("common/imaging/get-image", { id: id }).then(row => {
            const imageBase64 = row.get("image");
            let imageFileName = row.get("filename", "");
            if (StringUtil.isEmptyString(imageFileName)) {
                const descrField = tableRow.findComponentsByField("descr")[0] as Label;
                imageFileName = this.rowId + "_" + descrField.caption + row.get("file_ext", ".image")
            }
            this.viewOrDownload(imageBase64, imageFileName);
            descrLabel.busy = false;
        }).catch(error => {
            descrLabel.busy = false;
            CommonDialogs.showError(error);
        });
    }

    private viewOrDownload(imageBase64: string, fileName: string): void {
        if (ImageViewer.eligibleToView(fileName) === true)
            ImageViewer.viewImage(imageBase64, fileName);
        else {
            FileUtil.downloadBase64AsFile(imageBase64, fileName);
            Snackbar.showSnackbar("Downloaded " + fileName);
        }
    }

    async buttonAddImageOnClick(event: ClickEvent) {
        const layout = Layout.getLayout("common/imaging/UploadImages", {
            maxWidth: 1100,
            width: window.innerWidth * .55,
            backgroundColor: "defaultBackground",
            borderRadius: 4,
            borderWidth: 1,
            borderShadow: true,
            borderColor: "strokeSecondary"
        }) as UploadImages;
        layout.setup(this.rowType, this.rowId, null, (rowId: string) => this.imageDialog.close());
        this.imageDialog = CommonDialogs.createDialog(layout, { title: "Upload Images", okVisible: false });
        await this.imageDialog.show();
        if (layout.addedAnImage === true)
            this.populateForRow(this.rowType, this.rowId);
    }
}
